<template>
  <div>
    <ul>
      <!-- <li></li> -->
      <li v-for="(item, index) in dynamicList.items"
          :key="index">
        <img class="cover_img"
             :src="item.cover_imgs?JSON.parse(item.cover_imgs)[langtype]||item.cover_img:item.cover_img"
             alt="" />
        <div style="flex: 1;">
          <div class="middle">
            <p class="title">{{ item.titles?JSON.parse(item.titles)[langtype]||item.title:item.title }}</p>
            <p class="description">{{item.descriptions?JSON.parse(item.descriptions)[langtype]||item.description:item.description }}</p>
          </div>
          <div class="right">
            <p>{{ item.create_time }}</p>
            <el-button type="info"
                       @click="details(item)">{{$t('dynamicBox.ljxq')}}</el-button>
          </div>
        </div>
        <div class="right1">
          <p>{{ item.create_time }}</p>
          <el-button type="info"
                     @click="details(item)">{{$t('dynamicBox.ljxq')}}</el-button>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "BbjtwebsiteDynamicBox",
  props: {
    dynamics: {
      type: [Array, Object],
      default () {
        return [];
      },
    },
  },
  watch: {
    dynamics: {
      handler (message) {
        this.dynamicList = message;
        console.log(this.dynamicList)
      },
      deep: true,
    },
  },
  created () {
    switch (localStorage.getItem('lang')) {
      case "zh":
        this.langtype = 0;
        break;
      case "en":
        this.langtype = 1;
        break;
      case "es":
        this.langtype = 6;
        break;
      case "ru":
        this.langtype = 2;
        break;
      case "de":
        this.langtype = 3;
        break;
      case "fr":
        this.langtype = 4;
        break;
      case "ar":
        this.langtype = 5;
        break;
    }
  },
  data () {
    return {
      dynamicList: this.dynamics,
      langtype: 0
    };
  },
  mounted () {
    console.log(this.dynamicList);
  },

  methods: {
    details (item) {
      this.$router.push({
        path: this.$router.history.current.matched[0].path + "/dynamicDetail?id=" + item.id,
      });
    },
  },
};
</script>

<style lang="less" scoped>
ul li {
  border: 1px solid #eee;
  padding: 10px;
  display: flex;
  margin-bottom: 20px;
  position: relative;
  .cover_img {
    width: 374px;
    height: 250px;
    margin-right: 20px;
  }
  .middle {
    width: 60%;
    .title {
      color: #000;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    .description {
      font-size: 14px;
      color: #000;
    }
  }
  .right {
    position: absolute;
    bottom: 10px;
    // right: 5%;
    // width: 50%;
    right: 0;
    width: calc(100% - 404px);
    display: flex;
    text-align: center;
    justify-content: space-between;
    line-height: 60px;
    p {
      line-height: 40px;
    }
  }
}
.right1 {
  display: none;
}
@media screen and (max-width: 750px) {
  ul li {
    display: block;
    .right {
      display: none;
    }
    .middle {
      width: 100%;
    }
  }
  .right1 {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    p {
      line-height: 40px;
    }
  }
}
</style>
